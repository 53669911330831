import React, { useRef, useState } from 'react';
import classNames from 'classnames';
// import dateFormat from 'dateformat';

// import { padNum, round } from 'svs-utils/web';
// import { Input } from 'svs-utils/react';

import './braille.scss';

function Braille(props) {
    let [message, setMessage] = useState('');
    let [tab, setTab] = useState('one');
    let [highlightedDots, setHighlightedDots] = useState([0, 0, 0, 0, 0, 0]);
    let [highlightedLetter, setHighlightedLetter] = useState('');

    let letterHighlightTimeout = useRef(null);

    let changeTab = (newTab) => {
        if (tab !== newTab) {
            setTab(newTab);
        }
    };

    let createLetter = (char, i = '') => {
        let arr = returnBraille(char);
        return (
            <div className={classNames('letterBox', { highlightedLetter: highlightedLetter === char })} letter={String.fromCharCode(char)} key={char + i}>
                {(char >= 65 && char <= 90) && (
                    <React.Fragment>
                        <div className={`dot${arr[0]}`}></div>
                        <div className={`dot${arr[1]}`}></div>
                        <div className={`dot${arr[2]}`}></div>
                        <div className={`dot${arr[3]}`}></div>
                        <div className={`dot${arr[4]}`}></div>
                        <div className={`dot${arr[5]}`}></div>
                    </React.Fragment>
                )}
                <div className='letter'>{String.fromCharCode(char)}</div>
            </div>
        );
    };

    let handleDotClick = (i) => {
        var newHighlightedDots = [...highlightedDots];
        newHighlightedDots[i] = newHighlightedDots[i] ? 0 : 1;
        setHighlightedDots(newHighlightedDots);
    };

    let highlightLetter = () => {
        let brailleString = highlightedDots.join('');
        let highlightedLetter = returnLetter(brailleString);

        setHighlightedLetter(highlightedLetter);
        setHighlightedDots([0, 0, 0, 0, 0, 0]);

        if (letterHighlightTimeout.current) {
            clearTimeout(letterHighlightTimeout.current);
        }
        letterHighlightTimeout.current = setTimeout(() => setHighlightedLetter(''), 3000);
    };

    let letters = [];
    for (let i = 65; i <= 90; i++) {
        if (i !== 87) {
            letters.push(createLetter(i));
        }
    }
    for (let i = 0; i < 4; i++) {
        letters.push(createLetter(32, i.toString()));
    }
    letters.push(createLetter(87));

    let dots = highlightedDots.map((dot, i) => (
        <div className={`bigdot${dot}`} key={i} onClick={() => handleDotClick(i)}></div>
    ));

    let messageDivs = [];
    for (let [i, char] of message.split('').entries()) {
        messageDivs.push(createLetter(char.toUpperCase().charCodeAt(0), i.toString()));
    }

    return (
        <div className='brailleContainer'>
            <div className='tabs'>
                <span className={classNames({ selected: tab === 'one' })} onClick={() => changeTab('one')} tabfamily='menu' tab='1'><div>Type</div></span>
                <span className={classNames({ selected: tab === 'two' })} onClick={() => changeTab('two')} tabfamily='menu' tab='2'><div>Learn Letters</div></span>
            </div>
            {tab === 'one' ? (
                <React.Fragment>
                    <div id='main'>{messageDivs}</div>
                    <input className='mainInput' type='text' onChange={(event) => setMessage(event.target.value)} value={message} />
                </React.Fragment>
            ) : (
                <div id='main2' tabbody='menu2'>
                    <div></div>
                    <div style={{ height: '100%' }}>
                        <div id='brailleReference' style={{ width: 400, margin: 'auto', overflow: 'auto' }}>
                            {letters}
                        </div>
                    </div>
                    <div style={{ height: '100%' }}>
                        <div id='bigdots' style={{ width: 200, margin: 'auto', overflow: 'auto' }}>
                            {dots}
                            <div id='submit' onClick={highlightLetter}>Submit</div>
                        </div>
                    </div>
                    <div></div>
                </div>
            )}
        </div>
    );
}

function returnBraille(char) {
    switch(char) {
        case 65: case 'A': return [1, 0, 0, 0, 0, 0];
        case 66: case 'B': return [1, 0, 1, 0, 0, 0];
        case 67: case 'C': return [1, 1, 0, 0, 0, 0];
        case 68: case 'D': return [1, 1, 0, 1, 0, 0];
        case 69: case 'E': return [1, 0, 0, 1, 0, 0];
        case 70: case 'F': return [1, 1, 1, 0, 0, 0];
        case 71: case 'G': return [1, 1, 1, 1, 0, 0];
        case 72: case 'H': return [1, 0, 1, 1, 0, 0];
        case 73: case 'I': return [0, 1, 1, 0, 0, 0];
        case 74: case 'J': return [0, 1, 1, 1, 0, 0];
        case 75: case 'K': return [1, 0, 0, 0, 1, 0];
        case 76: case 'L': return [1, 0, 1, 0, 1, 0];
        case 77: case 'M': return [1, 1, 0, 0, 1, 0];
        case 78: case 'N': return [1, 1, 0, 1, 1, 0];
        case 79: case 'O': return [1, 0, 0, 1, 1, 0];
        case 80: case 'P': return [1, 1, 1, 0, 1, 0];
        case 81: case 'Q': return [1, 1, 1, 1, 1, 0];
        case 82: case 'R': return [1, 0, 1, 1, 1, 0];
        case 83: case 'S': return [0, 1, 1, 0, 1, 0];
        case 84: case 'T': return [0, 1, 1, 1, 1, 0];
        case 85: case 'U': return [1, 0, 0, 0, 1, 1];
        case 86: case 'V': return [1, 0, 1, 0, 1, 1];
        case 88: case 'X': return [1, 1, 0, 0, 1, 1];
        case 89: case 'Y': return [1, 1, 0, 1, 1, 1];
        case 90: case 'Z': return [1, 0, 0, 1, 1, 1];
        case 87: case 'W': return [0, 1, 1, 1, 0, 1];
        default: return [0, 0, 0, 0, 0, 0];
    }
}

function returnLetter(braille) {
    switch(braille) {
        case '100000': return 65; // A - 65
        case '101000': return 66; // B - 66
        case '110000': return 67; // C - 67
        case '110100': return 68; // D - 68
        case '100100': return 69; // E - 69
        case '111000': return 70; // F - 70
        case '111100': return 71; // G - 71
        case '101100': return 72; // H - 72
        case '011000': return 73; // I - 73
        case '011100': return 74; // J - 74
        case '100010': return 75; // K - 75
        case '101010': return 76; // L - 76
        case '110010': return 77; // M - 77
        case '110110': return 78; // N - 78
        case '100110': return 79; // O - 79
        case '111010': return 80; // P - 80
        case '111110': return 81; // Q - 81
        case '101110': return 82; // R - 82
        case '011010': return 83; // S - 83
        case '011110': return 84; // T - 84
        case '100011': return 85; // U - 85
        case '101011': return 86; // V - 86
        case '110011': return 88; // X - 88
        case '110111': return 89; // Y - 89
        case '100111': return 90; // Z - 90
        case '011101': return 87; // W - 87
        default: return '';
    }
}

export default Braille;
