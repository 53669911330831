import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import { GlobalStateProvider } from './utils/reactUtils.js';
import AppRouter from './router.js';

import 'svs-utils/css/mainStyles.scss';

var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <GlobalStateProvider>
                <AppRouter />
            </GlobalStateProvider>
        </BrowserRouter>
    </React.StrictMode>
);
