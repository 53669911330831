import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import dateFormat from 'dateformat';

// import { padNum } from 'svs-utils/web';
// import { Input } from 'svs-utils/react';

import './wordEvolution.scss';

let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~ ';
let mainDelay = 20;

function WordEvolution(props) {
    let [evolution, setEvolution] = useState('');
    let [evolutionType, setEvolutionType] = useState('charMatch');
    let [result, setResult] = useState('');
    let [word, setWord] = useState('');
    let [startTime, setStartTime] = useState(null);
    let [endTime, setEndTime] = useState(null);

    let evolutionTimeout = useRef(null);

    let startWordEvolution = () => {
        if (!word) {
            return;
        }

        if (!['charMatch', 'bruteForce'].includes(evolutionType)) {
            alert('unknown evolution type');
            return;
        }

        setStartTime(new Date());
        setEndTime(null);
        setResult('Evolving...');
        if (evolutionType === 'charMatch') {
            let randomString = '';
            for (let i = 0; i < word.length; i++) {
                randomString += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            startCharMatch(word, randomString);
        } else if (evolutionType === 'bruteForce') {
            bruteForce(word);
        }
    };

    let setMyTimeout = (func, delay = null) => {
        if (evolutionTimeout.current) {
            clearTimeout(evolutionTimeout.current);
        }

        delay = (delay == null ? mainDelay : delay);
        evolutionTimeout.current = setTimeout(func, delay);
    };

    let startCharMatch = (text, randomString) => {
        let iterations = 1;
        setMyTimeout(() => charMatchEvolve(text, randomString, iterations));
    };

    let charMatchEvolve = (text, randomString, iterations) => {
        for (let i = 0; i < text.length; i++) {
            if (text[i] !== randomString[i]) {
                let newChar = chars.charAt(Math.floor(Math.random() * chars.length));
                randomString = randomString.substr(0, i) + newChar + randomString.substr(i + 1);
            }
        }

        setEvolution(randomString);

        iterations++;
        if (text !== randomString) {
            setMyTimeout(() => charMatchEvolve(text, randomString, iterations));
        } else {
            setResult('Target matched! That took ' + iterations + ' generation(s)');
            setEndTime(new Date());
        }
    };

    let bruteForce = (text) => {
        text = text.toLowerCase();
        let guess = 'a';
        let iterations = 1;
        setMyTimeout(() => bruteForceEvolve(text, guess, iterations), 0);
    };

    let bruteForceEvolve = (text, guess, iterations) => {
        guess = stringAddAlphabetical(guess);

        setEvolution(guess);

        iterations++;
        if (text !== guess) {
            setMyTimeout(() => bruteForceEvolve(text, guess, iterations), 0);
        } else {
            setResult('Target matched! That took ' + iterations + ' generation(s)');
            setEndTime(new Date());
        }
    };

    let stringAddAlphabetical = (string) => {
        let alphaChars = 'abcdefghijklmnopqrstuvwxyz'; // ABCDEFGHIJKLMNOPQRSTUVWXYZ
        let lastChar = alphaChars[alphaChars.length - 1];
        string = reverse(string.toLowerCase());
        if (string[0] === lastChar) {
            if (string.length > 1) {
                var newString = alphaChars[0];
                for (var i = 1; i < string.length; i++) {
                    if (string[i] === lastChar) {
                        newString += alphaChars[0];
                        if (i === string.length - 1) {
                            newString += alphaChars[0];
                        }
                    } else {
                        newString += alphaChars[alphaChars.indexOf(string[i]) + 1] + string.substr(i + 1);
                        break;
                    }
                }
                string = newString;
            } else {
                string = alphaChars[0] + alphaChars[0];
            }
        } else {
            string = alphaChars[alphaChars.indexOf(string[0]) + 1] + string.substr(1);
        }
        return reverse(string);
    };

    let reverse = (s) => s.split('').reverse().join('');

    return (
        <div className='wordEvolutionContainer'>
            <h1>Word Evolution</h1>
            <p>
                Hello, this is my Word Evolution function. You enter the text and it tries to match the
                phrase by randomly choosing letters until it matches the correct letters.<br/><br/>
                Currently, it matches each character individually and stops guessing each character once
                it matches that character, but with no memory of what was previosly guessed. This is
                called Char Match No Memory. Another version is Brute Force (which has to match the
                whole string to be correct, in other words, there is no feedback on which characters are
                right (This is the method used to guess passwords)).
            </p>

            <div className='radioContainer' onClick={() => setEvolutionType('charMatch')}>
                <div className={classNames('radioButton', { checked: evolutionType === 'charMatch' })} radioGroup='option'>
                    <div className='radioInside'></div>
                </div>
                <span className='radioText'>Char Match No Memory</span>
            </div>
            <div className='radioContainer' onClick={() => setEvolutionType('bruteForce')}>
                <div className={classNames('radioButton', { checked: evolutionType === 'bruteForce' })} radioGroup='option'>
                    <div className='radioInside'></div>
                </div>
                <span className='radioText'>Brute Force</span>
            </div>

            <input id='word' type='text' placeholder='Enter word or phrase' value={word} onChange={(event) => setWord(event.target.value)} />
            <button id='goButton' type='button' onClick={startWordEvolution}>Go!</button>
            <p id='evolution'>{evolution || 'there doesnt seem to be any evolutions yet'}</p>
            <p id='result'>{result}</p>
            <p id='startTime'>Start time: <span>{startTime ? dateFormat(startTime, 'yyyy-mm-dd HH:MM:ss') : ''}</span></p>
            <p id='endTime'>End time: <span>{endTime ? dateFormat(endTime, 'yyyy-mm-dd HH:MM:ss') : ''}</span></p>
            <p id='totalTime'>Total time: <span>{endTime ? (((endTime - startTime) / 1000) + ' seconds') : ''}</span></p>

            <p>
                <br/>Versions to come: Char Match w/ Memory and Brute Force with more options
                (alphabetical(case and no case) and all chars).
                Maybe even something to show how many it's currently guessing per second.
            </p>
        </div>
    );
}

export default WordEvolution;
