// import React from 'react';

import { getGlobalStateUtils } from 'svs-utils/react';

var initialGlobalState = {
    '2048': {
        board: [],
        endScreenVisible: false,
        gameState: '',
        score: 0,
        tiles: [],
    },
    gameOfLife: {
        delay: 75, // 100
        gameState: 'paused',
        world: null,
        worldType: 'random', // empty, random
        worldHeight: 75,
        worldWidth: 100,
        windowHeight: window.innerHeight
    },
    minesweeper: {
        board: null,
        difficulty: 'easy',
        gameState: 'created',
        gameMessage: '',
        startTime: null,
        endTime: null,
        timeDiff: null,
        aiInterval: null,
        winCount: 0,
        lossCount: 0,
    },
    ticTacToe: {
        board: null,
        gameState: '',
        gameMessage: '',
        gameType: '',
    },
};

export var { GlobalStateProvider, useStateSlice } = getGlobalStateUtils(initialGlobalState);
