import React, { useEffect } from 'react';
import classNames from 'classnames';

import { Input } from 'svs-utils/react';

import { useStateSlice } from '../../utils/reactUtils.js';

import './ticTacToe.scss';

function createBoard() {
    var board = [
        [null, null, null],
        [null, null, null],
        [null, null, null],
    ];

    return board;
}

function isBoardEmpty(board) {
    return board.every((row) => row.every((cell) => cell === null));
}

function copyBoard(board) {
    return JSON.parse(JSON.stringify(board));
}

function getPlayer(board) { // Returns player who has the next turn on a board
    if (!board) {
        return null;
    }

    var xCount = 0
    var oCount = 0
    for (var row of board) {
        for (var cell of row) {
            if (cell === 'X') {
                xCount++;
            } else if (cell === 'O') {
                oCount++;
            }
        }
    }

    if (xCount > oCount) {
        return 'O';
    } else {
        return 'X';
    }
}

function getWinner(board) {
    var winner = null;

    var wins = [
        // horzontal win
        [[0, 0], [0, 1], [0, 2]],
        [[1, 0], [1, 1], [1, 2]],
        [[2, 0], [2, 1], [2, 2]],

        // vertical win
        [[0, 0], [1, 0], [2, 0]],
        [[0, 1], [1, 1], [2, 1]],
        [[0, 2], [1, 2], [2, 2]],

        // diagonal win
        [[0, 0], [1, 1], [2, 2]],
        [[0, 2], [1, 1], [2, 0]],
    ];

    for (var win of wins) {
        var [a, b, c] = win;
        if (board[a[0]][a[1]] != null && board[a[0]][a[1]] === board[b[0]][b[1]] && board[a[0]][a[1]] === board[c[0]][c[1]]) {
            return board[a[0]][a[1]];
        }
    }

    return winner;
}

function isTerminal(board) { // Returns True if game is over, False otherwise
    if (getWinner(board)) {
        return true;
    }

    for (var row of board) {
        for (var cell of row) {
            if (!cell) {
                return false;
            }
        }
    }

    return true;
}

function actions(board) { // Returns set of all possible actions (i, j) available on the board
    var moves = [];
    for (var [i, row] of board.entries()) {
        for (var [j, cell] of row.entries()) {
            if (!cell) {
                moves.push([i, j]);
            }
        }
    }

    return moves;
}

function result(board, action) { // Returns the board that results from making move (i, j) on the board
    var [i, j] = action;
    if (board[i][j]) {
        console.log('Invalid action');
        return board;
    }

    board = copyBoard(board);
    var turn = getPlayer(board);
    board[i][j] = turn;

    return board;
}

function TicTacToe(props) {
    var [tttSlice, setTTTSlice] = useStateSlice('ticTacToe');
    var { board, gameMessage, gameState, gameType } = tttSlice;

    var player = getPlayer(board);

    useEffect(() => {
        if (!['created', 'playing'].includes(gameState)) {
            return;
        }

        if (gameType === 'twoPlayer') {
            return;
        }

        if (!board) {
            return;
        }

        if (isBoardEmpty(board)) {
            if (player !== gameType) {
                setTimeout(makeAiMove, 50);
            }
            return;
        }

        if (player !== gameType) {
            makeAiMove();
        }
    }, [player]); // eslint-disable-line react-hooks/exhaustive-deps

    var playAgain = () => {
        setTTTSlice({ board: null, gameState: '', gameMessage: '', gameType: '' });
    };

    var startNewGame = (gameType) => {
        var board = createBoard();
        setTTTSlice({ board, gameState: 'created', gameMessage: '', gameType });
    };

    var cellClicked = (i, j) => {
        if (!['created', 'playing'].includes(gameState)) {
            return;
        }

        var player = getPlayer(board);
        if (gameType !== 'twoPlayer' && gameType !== player) {
            return;
        }

        if (board[i][j]) {
            return;
        }

        board = copyBoard(board);
        board[i][j] = player;

        var winner = getWinner(board);
        if (winner) {
            var message = 'You Won!';
            if (gameType === 'twoPlayer') {
                message = `${winner} won!`
            }
            setTTTSlice({ board, gameMessage: message, gameState: 'ended' });
        } else if (isTerminal(board)) {
            setTTTSlice({ board, gameMessage: 'The game is a tie.', gameState: 'ended' });
        } else {
            setTTTSlice({ board, gameState: 'playing' });
        }
    };

    var makeAiMove = () => {
        var start = new Date();
        var move = minimax(board);
        var end = new Date();
        console.log('time for ai to decide', (end - start) / 1000);

        board = result(board, move);

        var winner = getWinner(board);
        if (winner) {
            setTTTSlice({ board, gameMessage: `${winner} won!`, gameState: 'ended' });
        } else if (isTerminal(board)) {
            setTTTSlice({ board, gameMessage: 'The game is a tie.', gameState: 'ended' });
        } else {
            setTTTSlice({ board });
        }
    };

   var minimax = (board) => { // Returns the optimal action for the current player on the board
        if (isTerminal(board)) {
            return null;
        }

        var p = getPlayer(board);
        var move = null;
        if (p === 'X') {
            [, move] = maxMove(board);
        } else {
            [, move] = minMove(board);
        }

        return move;
    };

    var utility = (board) => { // Returns 1 if X has won the game, -1 if O has won, 0 otherwise
        var isWinner = getWinner(board);
        if (isWinner === 'X') {
            return 1;
        } else if (isWinner === 'O') {
            return -1;
        } else {
            return 0;
        }
    };

    var maxMove = (board, establishedMin = null) => {
        if (isTerminal(board)) {
            return [utility(board), null];
        }

        var As = actions(board);
        var v = -Infinity;
        var move = null;
        for (var [, action] of As.entries()) {
            var newBoard = result(board, action);
            var [u] = minMove(newBoard, v);
            if (u > v) {
                v = u;
                move = action;
            }
            if (establishedMin && v > establishedMin) {
                // console.log(`skipped ${len(As) - (i + 1)} branches in max`)
                return [v, move];
            }
        }

        return [v, move];
    };

    var minMove = (board, establishedMax = null) => {
        if (isTerminal(board)) {
            return [utility(board), null];
        }

        var As = actions(board);
        var v = Infinity;
        var move = null;
        for (var [, action] of As.entries()) {
            var newBoard = result(board, action);
            var [u] = maxMove(newBoard);
            if (u < v) {
                v = u;
                move = action;
            }
            if (establishedMax && v < establishedMax) {
                // console.log(`skipped ${len(As) - (i + 1)} branches in min`);
                return [v, move];
            }
        }

        return [v, move];
    };

    if (!gameState) {
        return (
            <div className='ticTacToeContainer'>
                <div></div>
                <div className='ticTacToe'>
                    <div className='gameTitle'>Tic-Tac-Toe!</div>
                    <Input type='button' label='Two Player Game' onClick={() => startNewGame('twoPlayer')} />
                    <Input type='button' label='Play as X vs AI' onClick={() => startNewGame('X')} />
                    <Input type='button' label='Play as O vs AI' onClick={() => startNewGame('O')} />
                </div>
                <div></div>
            </div>
        );
    }

    return (
        <div className='ticTacToeContainer'>
            <div></div>
            <div className='ticTacToe'>
                <div className='gameTitle'>Tic-Tac-Toe!</div>
                <div className={classNames('gameBoard', gameState)}>
                    {board && board.map((row, i) => (
                        <div className='boardRow' key={i}>
                            {row.map((cell, j) => (
                                <div
                                    className={classNames(`boardCell`, cell)}
                                    key={j}
                                    onClick={() => cellClicked(i, j)}
                                >
                                    {cell}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                {gameMessage && (
                    <React.Fragment>
                        <div className='gameMessage'>{gameMessage}</div>
                        <Input type='button' label='Play Again!' onClick={() => playAgain()} />
                    </React.Fragment>
                )}
            </div>
            <div></div>
        </div>
    );
}

export default TicTacToe;
