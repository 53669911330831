/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBomb, faFlag } from '@fortawesome/free-solid-svg-icons'

import { ajax, sleep } from 'svs-utils/web';
import { Input } from 'svs-utils/react';

// import * as pieceImages from '../../images/index.js';
import * as pieceImages from '../../svgs/index.js';

import './chess.scss';

var files = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
var ranks = [1, 2, 3, 4, 5, 6, 7, 8];

var getColumn = (j) => files[j];

var moveRegex = /^(?<type>[PRNBKQ])?(?<startingPosition>[a-h]|[1-8]|[a-h][1-8])?(?<takes>x)?(?<position>[a-h][1-8])(?:=(?<promotion>[RNBQ]))?(?<checkStatus>[+#])?$|^(?<c1>O-O)$|^(?<c2>O-O-O)$/;

function Chess(props) {

    var navigate = useNavigate();
    var urlParams = useParams();
    var { secondPath: gameId } = urlParams;

    var [board, setBoard] = useState(null);
    var [pieces, setPieces] = useState(null);
    var [gameState, setGameState] = useState('empty');
    var [gameType, setGameType] = useState(gameId ? 'onlineLink' : 'singleScreen');
    var [myColor, setMyColor] = useState('white');
    var [turn, setTurn] = useState('white');
    var [selectedSquare, setSelectedSquare] = useState('');
    var [showPossibleMoves, setShowPossibleMoves] = useState(false);
    var [showSquareNames, setShowSquareNames] = useState(false);
    var [possibleMoves, setPossibleMoves] = useState([]);
    var [lastMove, setLastMove] = useState('');
    var [promotionWaiting, setPromotionWaiting] = useState(false);
    var [onlineGameStatus, setOnlineGameStatus] = useState({ whiteConnected: false, blackConnected: false });
    var [applyMovesList, setApplyMovesList] = useState(null);

    var wsRef = useRef(null);
    var checkStatusInterval = useRef(null);
    var handleNewMessageRef = useRef(null);
    var promotionRef = useRef({ choice: '', waitingForChoice: false, square: '' });

    useEffect(() => {
        if (gameId) {
            startNewGame();
        }
    }, []);

    useEffect(() => {
        if (lastMove && !lastMove.endsWith('+') && !lastMove.endsWith('#')) {
            var inCheckMate = isInCheckMate(turn);
            if (inCheckMate) {
                setLastMove(lastMove + '#');
            } else {
                var inCheck = isInCheck(turn);
                if (inCheck) {
                    setLastMove(lastMove + '+');
                }
            }

            console.log('move', lastMove);
        }
    }, [lastMove]);

    useEffect(() => {
        handleNewMessageRef.current = handleNewMessage;
    });

    useEffect(() => {
        if (applyMovesList?.length) {
            let [nextMove, ...newMovesList] = applyMovesList;
            processMovesList([nextMove]).then(() => setApplyMovesList(newMovesList));
        }
    }, [applyMovesList]);

    var startNewGame = () => {
        if (gameType === 'onlineLink') {
            startOnlineLinkGame();
        } else {
            var { board: newBoard, pieces: newPieces } = createBoard();
            setBoard(newBoard);
            setPieces(newPieces)
            setGameState('created');

            // setTimeout(() => {
            //     var movesList = [['Pg4','Pc5'],['Pa4','Pd5'],['Pg5','Pa6'],['Ph4','Pb6'],['Nc3','Pd4'],['Ne4','Bb7'],['Pd3','Nd7'],['Pa5','Qc7'],['Pxb6','Qxb6'],['Pb3','Pg6'],['Ba3','Ph5'],['Pxh6','Bxh6'],['Bxc5','Qc6'],['Bxd4','Bf4'],['Bxh8','Bb8'],['Rh3','Qe6'],['Pxh5','Nxh6'],['Pxg6','Ng4'],['Pg7','Qxg6'],['Rh4','Bh2'],['Bh3','Nge5'],['Qd2','Kd8'],['Pf4','Kc8'],['Pxe5','Kb8'],['Rg4','Ka7'],['Rxg6','Pxg6'],['Bxd7','Bxg1'],['Pb4','Bxd4'],['Ra5','Bxb6'],['Ra4','Pg5'],['Nxc5','Pg4'],['Nxa6','Pg3'],['Nxc5+','Kb8'],['Pg8=Q+','Kc7'],['Rxa8','Bxa8'],['Qxa8','Pg2'],['Kf2','Pxg1=Q#'],[]];
            //     setApplyMovesList(movesList);
            //     // processMovesList(movesList);
            // }, 500);
        }
    }

    var startOnlineLinkGame = async () => {
        if (!gameId) {
            var results = await ajax({ endPoint: '/chess/createNewOnlineLinkGame' });
            if (results.result) {
                navigate(`/chess/${results.hashId}`);
            } else {
                alert(results.desc);
            }
        } else {
            if (!wsRef.current) {
                // the /temp is in here because redbird doesn't recognize the /ws endpoint when the ?token= is after it
                var protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
                var host = window.location.host;
                if (host.startsWith('dev.') && host.endsWith(':2999')) {
                    host = host.replace(':2999', ':3000');
                }

                wsRef.current = new WebSocket(`${protocol}://${host}/api/ws/chess`);
                wsRef.current.onopen = () => {
                    wsRef.current.send(JSON.stringify({ type: 'loadGame', gameId }));

                    if (!checkStatusInterval.current) {
                        checkStatusInterval.current = setInterval(() => {
                            if (wsRef.current) {
                                wsRef.current.send(JSON.stringify({ type: 'checkStatus' }));
                            }
                        }, 5000);
                    }
                };
                wsRef.current.onmessage = (event) => handleNewMessageRef.current(event);
                wsRef.current.onerror = (...args) => console.log('error connecting ws', args);
                wsRef.current.onclose = (event) => {
                    console.log('ws close event', event);
                    wsRef.current = null;
                };

                // on will unmount, close the ws
            }
        }
    };

    var handleNewMessage = (event) => {
        var data = JSON.parse(event.data);

        if (data.type === 'loadGame') {
            if (data.result) {
                wsRef.current.send(JSON.stringify({ type: 'checkStatus' }));

                var newLastMove = data.moves[data.moves.length - 1];
                if (!newLastMove.length && data.moves.length > 1) {
                    newLastMove = data.moves[data.moves.length - 2];
                }
                newLastMove = newLastMove.length > 0 ? newLastMove[newLastMove.length - 1] : '';

                var { board, pieces } = createBoard(data.pieces); // eslint-disable-line no-redeclare
                setBoard(board);
                setPieces(pieces);
                setMyColor(data.color)
                setGameState('created');
                setLastMove(newLastMove)
                setTurn(data.gameState === 'white' ? 'white' : 'black');
            } else {
                alert(data.desc);
            }
        } else if (data.type === 'status') {
            setOnlineGameStatus(data);
        } else if (data.type === 'move') {
            if (data.result) {
                var movedPiece = pieces.find((next) => next.name === data.pieceName);
                var { i: newI, j: newJ } = getPositionFromName(data.to);

                var existingPiece = board[newI][newJ].getPiece(pieces);
                if (existingPiece && existingPiece.color !== movedPiece.color) {
                    existingPiece.captured = true;
                }

                movedPiece.rank = newI + 1;
                movedPiece.file = files[newJ];
                var moveName = `${movedPiece.type}${existingPiece?.captured ? 'x' : ''}${data.to}`;

                if (movedPiece.type === 'K' && movedPiece.moves === 0 && ['c', 'g'].includes(movedPiece.file)) {
                    // castling
                    var rook = board[newI][movedPiece.file === 'c' ? 0 : 7].getPiece(pieces); // if c, then rook from file a, else rook from file h
                    rook.file = movedPiece.file === 'c' ? 'd' : 'f';
                    rook.moves++;
                    moveName = movedPiece.file === 'c' ? '0-0-0' : '0-0';
                } else if (movedPiece.type === 'P') {
                    // en passant
                    var opponentPawn = null;
                    if (movedPiece.color === 'white' && movedPiece.rank === 6) {
                        opponentPawn = board[newI - 1][newJ]?.getPiece(pieces);
                        if (opponentPawn?.type === 'P' && opponentPawn.color === 'black' && opponentPawn.moves === 1 && lastMove === `P${opponentPawn.file}${opponentPawn.rank}`) {
                            opponentPawn.captured = true;
                            moveName = `${movedPiece.type}x${data.to}`; // we captured a piece
                        }
                    } else if (movedPiece.color === 'black' && movedPiece.rank === 3) {
                        opponentPawn = board[newI + 1][newJ]?.getPiece(pieces);
                        if (opponentPawn?.type === 'P' && opponentPawn.color === 'white' && opponentPawn.moves === 1 && lastMove === `P${opponentPawn.file}${opponentPawn.rank}`) {
                            opponentPawn.captured = true;
                            moveName = `${movedPiece.type}x${data.to}`; // we captured a piece
                        }
                    }

                    // promotion
                    if ((movedPiece.color === 'black' && movedPiece.rank === 1) || (movedPiece.color === 'white' && movedPiece.rank === 8)) {
                        if (data.promotionTo) {
                            movedPiece.type = data.promotionTo;
                            moveName += '=' + data.promotionTo;
                        }
                    }
                }

                movedPiece.moves++;

                setBoard(board)
                setLastMove(moveName);
                setPossibleMoves([]);
                setSelectedSquare('')
                setTurn(turn === 'white' ? 'black' : 'white');
            } else {
                alert(data.desc);
            }
        }
    };

    var createBoard = (pieces) => {
        var board = [];
        for (var rank of ranks) {
            var row = [];
            for (var [j, file] of files.entries()) {
                var square = {
                    name: `${file}${rank}`,
                    getPiece(pieces) {
                        for (var piece of pieces) {
                            if (!piece.captured && this.name === piece.position) {
                                return piece;
                            }
                        }
                        return null;
                    },
                };
                if (rank % 2) { // odd numbered rows
                    square.color = (j % 2) ? 'light' : 'dark';
                } else {
                    square.color = (j % 2) ? 'dark' : 'light';
                }
                row.push(square);
            }
            board.push(row);
        }

        pieces = pieces || [
            { name: 'whiteR1', rank: 1, file: 'a', type: 'R', color: 'white', captured: false, moves: 0 },
            { name: 'whiteN1', rank: 1, file: 'b', type: 'N', color: 'white', captured: false, moves: 0 },
            { name: 'whiteB1', rank: 1, file: 'c', type: 'B', color: 'white', captured: false, moves: 0 },
            { name: 'whiteQ', rank: 1, file: 'd', type: 'Q', color: 'white', captured: false, moves: 0 },
            { name: 'whiteK', rank: 1, file: 'e', type: 'K', color: 'white', captured: false, moves: 0 },
            { name: 'whiteB2', rank: 1, file: 'f', type: 'B', color: 'white', captured: false, moves: 0 },
            { name: 'whiteK2', rank: 1, file: 'g', type: 'N', color: 'white', captured: false, moves: 0 },
            { name: 'whiteR2', rank: 1, file: 'h', type: 'R', color: 'white', captured: false, moves: 0 },
            { name: 'whiteP1', rank: 2, file: 'a', type: 'P', color: 'white', captured: false, moves: 0 },
            { name: 'whiteP2', rank: 2, file: 'b', type: 'P', color: 'white', captured: false, moves: 0 },
            { name: 'whiteP3', rank: 2, file: 'c', type: 'P', color: 'white', captured: false, moves: 0 },
            { name: 'whiteP4', rank: 2, file: 'd', type: 'P', color: 'white', captured: false, moves: 0 },
            { name: 'whiteP5', rank: 2, file: 'e', type: 'P', color: 'white', captured: false, moves: 0 },
            { name: 'whiteP6', rank: 2, file: 'f', type: 'P', color: 'white', captured: false, moves: 0 },
            { name: 'whiteP7', rank: 2, file: 'g', type: 'P', color: 'white', captured: false, moves: 0 },
            { name: 'whiteP8', rank: 2, file: 'h', type: 'P', color: 'white', captured: false, moves: 0 },
            { name: 'blackP1', rank: 7, file: 'a', type: 'P', color: 'black', captured: false, moves: 0 },
            { name: 'blackP2', rank: 7, file: 'b', type: 'P', color: 'black', captured: false, moves: 0 },
            { name: 'blackP3', rank: 7, file: 'c', type: 'P', color: 'black', captured: false, moves: 0 },
            { name: 'blackP4', rank: 7, file: 'd', type: 'P', color: 'black', captured: false, moves: 0 },
            { name: 'blackP5', rank: 7, file: 'e', type: 'P', color: 'black', captured: false, moves: 0 },
            { name: 'blackP6', rank: 7, file: 'f', type: 'P', color: 'black', captured: false, moves: 0 },
            { name: 'blackP7', rank: 7, file: 'g', type: 'P', color: 'black', captured: false, moves: 0 },
            { name: 'blackP8', rank: 7, file: 'h', type: 'P', color: 'black', captured: false, moves: 0 },
            { name: 'blackR1', rank: 8, file: 'a', type: 'R', color: 'black', captured: false, moves: 0 },
            { name: 'blackN1', rank: 8, file: 'b', type: 'N', color: 'black', captured: false, moves: 0 },
            { name: 'blackB1', rank: 8, file: 'c', type: 'B', color: 'black', captured: false, moves: 0 },
            { name: 'blackQ', rank: 8, file: 'd', type: 'Q', color: 'black', captured: false, moves: 0 },
            { name: 'blackK', rank: 8, file: 'e', type: 'K', color: 'black', captured: false, moves: 0 },
            { name: 'blackB2', rank: 8, file: 'f', type: 'B', color: 'black', captured: false, moves: 0 },
            { name: 'blackK2', rank: 8, file: 'g', type: 'N', color: 'black', captured: false, moves: 0 },
            { name: 'blackR2', rank: 8, file: 'h', type: 'R', color: 'black', captured: false, moves: 0 },
        ];

        for (var piece of pieces) {
            Object.defineProperty(piece, 'position', {
                get() {
                    return `${this.file}${this.rank}`;
                }
            });
        }

        return { board, pieces };
    };

    var processMovesList = async (moves) => {
        var delay = 500;

        for (var move of moves) {
            var nextTurn = 'white';
            for (var nextMove of move) {
                var match = nextMove.match(moveRegex);
                if (!match) {
                    return console.log('unable to match move to regex', nextMove);
                }

                match = match.groups;

                var pieceList = pieces.filter((p) => p.captured === false && p.color === nextTurn && p.type === match.type && pieceMatchesMoveStartingPosition(p, match));
                var piecePositions = pieceList.map((p) => getPossibleMoves(p, pieces));
                pieceList = pieceList.filter((p, i) => piecePositions[i].includes(match.position));

                if (pieceList.length === 1) {
                    console.log(`moving ${match.type} to ${match.position}`);
                    var piece = pieceList[0];
                    pieceClicked(piece);
                    await sleep(delay);
                    squareClicked(match.position);

                    if (match.promotion) {
                        await sleep(delay);
                        setPromotionWaiting(false);
                        promotionRef.current = { ...promotionRef.current, waitingForChoice: false, choice: match.promotion, rank: '', file: '' };
                        while (turn === nextTurn) {
                            await sleep(10);
                        }
                    }
                } else {
                    return console.log('invalid number of piece results', nextMove, pieceList);
                }

                nextTurn = 'black';

                await sleep(delay);
            }
        }
    };

    var pieceMatchesMoveStartingPosition = (piece, match) => {
        if (!match.startingPosition) {
            return true; // if starting position not specified, assume it matches
        }

        if (match.startingPosition.length === 2) {
            var piecePositionName = getNameFromPosition(getPositionFromPiece(piece));
            if (match.startingPosition === piecePositionName) {
                return true;
            }
        } else if (files.includes(match.startingPosition)) {
            if (piece.file === match.startingPosition) {
                return true;
            }
        } else if (ranks.includes(match.startingPosition)) {
            if (piece.rank === match.startingPosition) {
                return true;
            }
        }

        return false;
    };

    var makePiecesCopy = () => {
        var piecesCopy = [];
        for (var piece of pieces) {
            var pieceCopy = {
                ...piece,
                get position() {
                    return `${this.file}${this.rank}`;
                }
            };
            piecesCopy.push(pieceCopy);
        }

        return piecesCopy;
    };

    var pieceClicked = (piece) => {
        var squareName = `${piece.file}${piece.rank}`;

        var possibleMoves = [];
        if (!selectedSquare) {
            if (gameType === 'onlineLink') {
                if (piece.color !== myColor) {
                    return;
                }
            } else {
                if (piece.color !== turn) {
                    return;
                }
            }

            possibleMoves = getPossibleMoves(piece);
            setSelectedSquare(squareName)
            setPossibleMoves(possibleMoves);
        } else {
            var prevPosition = getPositionFromName(selectedSquare);
            var prevSquare = board[prevPosition.i][prevPosition.j];
            if (selectedSquare === squareName) {
                setSelectedSquare('');
                setPossibleMoves([]);
            } else if (piece.color === prevSquare.getPiece(pieces).color) {
                possibleMoves = getPossibleMoves(piece);
                setSelectedSquare(squareName);
                setPossibleMoves(possibleMoves);
            }
        }

        squareClicked(squareName);
    };

    var squareClicked = async (squareName) => {
        if (gameType === 'onlineLink') {
            if (myColor !== turn) {
                return;
            }
        }

        if (promotionWaiting) {
            return;
        }

        if (possibleMoves.includes(squareName)) {
            var { i, j } = getPositionFromName(selectedSquare);
            var piece = board[i][j].getPiece(pieces);
            var { i: newI, j: newJ } = getPositionFromName(squareName);

            var existingPiece = board[newI][newJ].getPiece(pieces);
            if (existingPiece && existingPiece.color !== piece.color) {
                existingPiece.captured = true;
            }

            piece.rank = newI + 1;
            piece.file = files[newJ];
            var moveName = `${piece.type}${existingPiece?.captured ? 'x' : ''}${squareName}`;

            var promotionTo = null;
            if (piece.type === 'K' && piece.moves === 0 && ['c', 'g'].includes(piece.file)) {
                // castling
                var rook = board[newI][piece.file === 'c' ? 0 : 7].getPiece(pieces); // if c, then rook from file a, else rook from file h
                rook.file = piece.file === 'c' ? 'd' : 'f';
                rook.moves++;
                moveName = piece.file === 'c' ? '0-0-0' : '0-0';
            } else if (piece.type === 'P') {
                // en passant
                var opponentPawn = null;
                if (piece.color === 'white' && piece.rank === 6) {
                    opponentPawn = board[newI - 1][newJ]?.getPiece(pieces);
                    if (opponentPawn?.type === 'P' && opponentPawn.color === 'black' && opponentPawn.moves === 1 && lastMove === `P${opponentPawn.file}${opponentPawn.rank}`) {
                        opponentPawn.captured = true;
                        moveName = `${piece.type}x${squareName}`; // we captured a piece
                    }
                } else if (piece.color === 'black' && piece.rank === 3) {
                    opponentPawn = board[newI + 1][newJ]?.getPiece(pieces);
                    if (opponentPawn?.type === 'P' && opponentPawn.color === 'white' && opponentPawn.moves === 1 && lastMove === `P${opponentPawn.file}${opponentPawn.rank}`) {
                        opponentPawn.captured = true;
                        moveName = `${piece.type}x${squareName}`; // we captured a piece
                    }
                }

                // promotion
                if ((piece.color === 'black' && piece.rank === 1) || (piece.color === 'white' && piece.rank === 8)) {
                    setPromotionWaiting(true);
                    promotionRef.current = { ...promotionRef.current, waitingForChoice: true, rank: piece.rank, file: piece.file };
                    await sleep(100);
                    while (promotionRef.current.waitingForChoice) {
                        await sleep(100);
                    }
                    piece.type = promotionRef.current.choice;
                    promotionTo = piece.type;
                    moveName += '=' + promotionTo;
                }
            }

            piece.moves++;

            if (wsRef.current) {
                wsRef.current.send(JSON.stringify({ type: 'move', pieceName: piece.name, to: squareName, promotionTo }));
            }

            setBoard(board)
            setLastMove(moveName)
            setPossibleMoves([])
            setSelectedSquare('')
            setTurn(turn === 'white' ? 'black' : 'white');
        }
    };

    var promotionClicked = (newType) => {
        promotionRef.current = { ...promotionRef.current, choice: newType, waitingForChoice: false, rank: '', file: '' };
        setPromotionWaiting(false);
    };

    var getPositionFromName = (name) => {
        var [file, rank] = name.split('');
        var fileIndex = files.indexOf(file);
        return { i: parseInt(rank) - 1, j: fileIndex };
    };

    var getPositionFromPiece = (piece) => {
        var { file, rank } = piece;
        var fileIndex = files.indexOf(file);
        return { i: rank - 1, j: fileIndex };
    };

    var getNameFromPosition = (position) => {
        var { i, j } = position;
        return `${files[j]}${ranks[i]}`;
    };

    var getAllPossibleMovesForColor = (color, pieces, fromIsInCheck = false) => {
        var possibleMoves = [];
        for (var piece of pieces) {
            if (piece.color !== color) {
                continue;
            }
            var moves = getPossibleMoves(piece, pieces, fromIsInCheck, true);
            for (var move of moves) {
                if (!possibleMoves.includes(move)) {
                    possibleMoves.push(move);
                }
            }
        }
        return possibleMoves;
    };

    var getPossibleMoves = (piece, piecesToUse = null, fromIsInCheck = false, fromGetAllMoves = false) => {
        piecesToUse = piecesToUse || pieces;

        var possibleMoves = [];
        var { i, j } = getPositionFromPiece(piece);

        switch (piece.type) {
            case 'P':
                var direction = 1;
                if (piece.color === 'black') {
                    direction = -1;
                }
                var newSquare = board[i + direction]?.[j];
                if (newSquare && !newSquare.getPiece(piecesToUse)) {
                    possibleMoves.push({ i: i + direction, j });
                }

                if (piece.moves === 0) {
                    newSquare = board[i + direction]?.[j];
                    if (newSquare && !newSquare.getPiece(piecesToUse)) {
                        newSquare = board[i + (direction * 2)]?.[j];
                        if (newSquare && !newSquare.getPiece(piecesToUse)) {
                            possibleMoves.push({ i: i + (direction * 2), j });
                        }
                    }
                }

                newSquare = board[i + direction]?.[j + 1];
                if (newSquare?.getPiece(piecesToUse) && newSquare.getPiece(piecesToUse).color !== piece.color) {
                    possibleMoves.push({ i: i + direction, j: j + 1 });
                }
                newSquare = board[i + direction]?.[j - 1];
                if (newSquare?.getPiece(piecesToUse) && newSquare.getPiece(piecesToUse).color !== piece.color) {
                    possibleMoves.push({ i: i + direction, j: j - 1 });
                }

                // en passant
                var opponentPawn = null;
                if (piece.color === 'white' && piece.rank === 5) {
                    opponentPawn = board[i][j + 1]?.getPiece(piecesToUse);
                    if (opponentPawn?.type === 'P' && opponentPawn.color === 'black' && opponentPawn.moves === 1 && lastMove === `P${opponentPawn.file}${opponentPawn.rank}`) {
                        possibleMoves.push({ i: i + direction, j: j + 1 });
                    }
                    opponentPawn = board[i][j - 1]?.getPiece(piecesToUse);
                    if (opponentPawn?.type === 'P' && opponentPawn.color === 'black' && opponentPawn.moves === 1 && lastMove === `P${opponentPawn.file}${opponentPawn.rank}`) {
                        possibleMoves.push({ i: i + direction, j: j - 1 });
                    }
                } else if (piece.color === 'black' && piece.rank === 4) {
                    opponentPawn = board[i][j + 1]?.getPiece(piecesToUse);
                    if (opponentPawn?.type === 'P' && opponentPawn.color === 'white' && opponentPawn.moves === 1 && lastMove === `P${opponentPawn.file}${opponentPawn.rank}`) {
                        possibleMoves.push({ i: i + direction, j: j + 1 });
                    }
                    opponentPawn = board[i][j - 1]?.getPiece(piecesToUse);
                    if (opponentPawn?.type === 'P' && opponentPawn.color === 'white' && opponentPawn.moves === 1 && lastMove === `P${opponentPawn.file}${opponentPawn.rank}`) {
                        possibleMoves.push({ i: i + direction, j: j - 1 });
                    }
                }

                break;
            case 'B': case 'Q': case 'R':
                var directions = {
                    R: [[1, 0], [-1, 0], [0, 1], [0, -1]],
                    B: [[1, 1], [-1, 1], [1, -1], [-1, -1]],
                };
                directions.Q = [...directions.R, ...directions.B];

                for (var [dirI, dirJ] of directions[piece.type]) {
                    var newI = i + dirI;
                    var newJ = j + dirJ;
                    while ((0 <= newI && newI <= 7) && (0 <= newJ && newJ <= 7)) {
                        var nextSquare = board[newI][newJ];
                        if (nextSquare.getPiece(piecesToUse)) {
                            if (nextSquare.getPiece(piecesToUse).color !== piece.color) {
                                possibleMoves.push({ i: newI, j: newJ });
                            }
                            break;
                        } else {
                            possibleMoves.push({ i: newI, j: newJ });
                        }
                        newI += dirI;
                        newJ += dirJ;
                    }
                }
                break;
            case 'N':
                possibleMoves.push({ i: i + 1, j: j + 2 });
                possibleMoves.push({ i: i - 1, j: j + 2 });
                possibleMoves.push({ i: i + 2, j: j - 1 });
                possibleMoves.push({ i: i + 2, j: j + 1 });
                possibleMoves.push({ i: i + 1, j: j - 2 });
                possibleMoves.push({ i: i - 1, j: j - 2 });
                possibleMoves.push({ i: i - 2, j: j + 1 });
                possibleMoves.push({ i: i - 2, j: j - 1 });
                break;
            case 'K':
                possibleMoves.push({ i: i + 1, j: j + 1 });
                possibleMoves.push({ i: i + 1, j: j + 0 });
                possibleMoves.push({ i: i + 1, j: j - 1 });
                possibleMoves.push({ i: i + 0, j: j + 1 });
                possibleMoves.push({ i: i + 0, j: j - 1 });
                possibleMoves.push({ i: i - 1, j: j + 1 });
                possibleMoves.push({ i: i - 1, j: j + 0 });
                possibleMoves.push({ i: i - 1, j: j - 1 });

                // castling
                if (!fromIsInCheck && !fromGetAllMoves && piece.moves === 0) {
                    var rooks = piecesToUse.filter((p) => p.color === piece.color && p.type === 'R' && !p.captured && p.moves === 0);
                    if (rooks.length) {
                        var allOpponentMoves = getAllPossibleMovesForColor((piece.color === 'white') ? 'black' : 'white', piecesToUse);
                        for (var rook of rooks) {
                            var neighbors = (rook.file === 'a') ? ['c', 'd', 'b'] : ['f', 'g', 'g']; // double g just to have 3 options, can just check the same square twice
                            var square1 = board[i][files.indexOf(neighbors[0])];
                            var square2 = board[i][files.indexOf(neighbors[1])];
                            var square3 = board[i][files.indexOf(neighbors[1])];
                            if (square1.getPiece(piecesToUse) || square2.getPiece(piecesToUse) || square3.getPiece(piecesToUse)) {
                                // if there is a piece there, then you can't castle
                                continue;
                            }

                            var squareName1 = neighbors[0] + piece.rank;
                            var squareName2 = neighbors[1] + piece.rank;
                            var squareName3 = neighbors[2] + piece.rank;
                            if (allOpponentMoves.includes(squareName1) || allOpponentMoves.includes(squareName2) || allOpponentMoves.includes(squareName3)) {
                                // if passes through or lands on an attacked square, you can't castle
                                continue;
                            }

                            possibleMoves.push({ i, j: files.indexOf((rook.file === 'a') ? 'c' : 'g') });
                        }
                    }
                }
                break;
            default:
                break;
        }

        // also needs to filter moves that would put your king in check
        possibleMoves = possibleMoves.filter(({ i: newI, j: newJ }) => {
            if ((0 <= newI && newI <= 7) && (0 <= newJ && newJ <= 7)) {
                var sameColorPieceExists = piece.color === board[newI][newJ].getPiece(piecesToUse)?.color;
                return !sameColorPieceExists;
            } else {
                return false;
            }
        });

        if (!fromIsInCheck) {
            possibleMoves = possibleMoves.filter(({ i: newI, j: newJ }) => {
                var piecesCopy = makePiecesCopy();
                var pieceCopy = piecesCopy.find((next) => next.name === piece.name);

                var existingPiece = board[newI][newJ].getPiece(piecesCopy);
                if (existingPiece && existingPiece.color !== piece.color) {
                    existingPiece.captured = true;
                }

                pieceCopy.rank = newI + 1;
                pieceCopy.file = files[newJ];
                return !isInCheck(piece.color, piecesCopy);
            });
        }

        possibleMoves = possibleMoves.map((position) => getNameFromPosition(position));
        possibleMoves.sort()

        return possibleMoves;
    };

    var isInCheck = (color, piecesToUse = null) => {
        piecesToUse = piecesToUse || pieces;

        var king = piecesToUse.find((piece) => piece.name === `${color}K`);
        var kingSquareName = king.file + king.rank;
        var opponentPieces = piecesToUse.filter((piece) => !piece.captured && piece.color !== color);

        for (var opponentPiece of opponentPieces) {
            var possibleMoves = getPossibleMoves(opponentPiece, piecesToUse, true);
            if (possibleMoves.includes(kingSquareName)) {
                return true;
            }
        }

        return false;
    };

    var isInCheckMate = (color) => {
        var isInCheckResult = isInCheck(color);
        if (isInCheckResult) {
            var myPieces = pieces.filter((piece) => !piece.captured && piece.color === color);
            for (var piece of myPieces) {
                var possibleMoves = getPossibleMoves(piece);
                if (possibleMoves.length) {
                    return false;
                }
            }

            return true; // in check and no possible moves to get out
        }

        return false;
    };

    var { whiteConnected, blackConnected } = onlineGameStatus;

    if (gameState === 'empty') {
        var gameTypeItems = [
            { value: 'singleScreen', label: 'Single Screen' },
            { value: 'onlineLink', label: 'Online with Friend' },
        ];

        return (
            <div className='chessContainer'>
                <div className='chessMenu'>
                    <div className='welcomeMessage'>Welcome to Chess!</div>
                    <Input
                        label='Game Type'
                        type='select'
                        value={gameType}
                        selectItems={gameTypeItems}
                        onChange={setGameType}
                    />
                    <Input label='Start Game!' type='button' onClick={startNewGame} />
                </div>
            </div>
        )
    } else {
        var whiteInCheck = isInCheck('white');
        var blackInCheck = isInCheck('black');

        var whiteInCheckMate = isInCheckMate('white');
        var blackInCheckMate = isInCheckMate('black');

        let boardToRender = [];
        if (myColor === 'white') {
            // a8 -> h8
            // a1 -> h1
            boardToRender = [...board];
            boardToRender.reverse();
        } else if (myColor === 'black') {
            // h1 -> a1
            // h8 -> a8
            boardToRender = [...board.map((row) => [...row].reverse())];
        }

        var whiteCapturedPieces = pieces.filter((piece) => piece.color === 'white' && piece.captured === true).reverse();
        var blackCapturedPieces = pieces.filter((piece) => piece.color === 'black' && piece.captured === true);

        return (
            <div className='chessContainer'>
                <div className='chessBoardContainer'>
                    <div className={classNames('chessBoard', { reversed: myColor === 'black' })}>
                        {promotionWaiting && (
                            <div className={`boardRow promotionChoiceDiv boxShadow rank${promotionRef.current.rank} file${promotionRef.current.file.toUpperCase()}`}>
                                <div className='boardSquare lightSquare' onClick={() => promotionClicked('R')}>
                                    <img src={pieceImages[turn + 'R']} alt={`${turn} R`} />
                                </div>
                                <div className='boardSquare darkSquare' onClick={() => promotionClicked('N')}>
                                    <img src={pieceImages[turn + 'N']} alt={`${turn} N`} />
                                </div>
                                <div className='boardSquare lightSquare' onClick={() => promotionClicked('B')}>
                                    <img src={pieceImages[turn + 'B']} alt={`${turn} B`} />
                                </div>
                                <div className='boardSquare darkSquare' onClick={() => promotionClicked('Q')}>
                                    <img src={pieceImages[turn + 'Q']} alt={`${turn} Q`} />
                                </div>
                            </div>
                        )}
                        {boardToRender.map((row, i) => (
                            <div className='boardRow' key={i + 1}>
                                {row.map((square, j) => (
                                    <div
                                        className={classNames(
                                            `boardSquare ${square.color}Square`,
                                            { selected: square.name === selectedSquare, possibleMove: (showPossibleMoves && possibleMoves.includes(square.name)) }
                                        )}
                                        key={getColumn(j)}
                                        onClick={() => squareClicked(square.name)}
                                        squarename={square.name}
                                    >
                                        {showSquareNames && (
                                            <div className='squareName'>{square.name}</div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                        {pieces.filter((piece) => !piece.captured).map((piece) => (
                            <div
                                className={classNames(`gamePiece ${piece.color} rank${piece.rank} file${piece.file.toUpperCase()}`)}
                                key={piece.name}
                                onClick={() => pieceClicked(piece)}
                            >
                                <img src={pieceImages[piece.color + piece.type]} alt={`${piece.color} ${piece.type}`} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='gameInfoContainer'>
                    <div className={classNames('myColor', myColor)}>My Color: {myColor}</div>
                    <div className={classNames('turnIndicator', turn)}>Turn: {turn}</div>
                    {(whiteInCheck || blackInCheck) && (
                        <div className={classNames('checkStatus', { black: blackInCheck })}>{whiteInCheck ? 'White' : 'Black'} is in check!</div>
                    )}
                    {(whiteInCheckMate || blackInCheckMate) && (
                        <div className={classNames('checkMateStatus', { black: blackInCheckMate })}>{whiteInCheckMate ? 'White' : 'Black'} has been checkmated!</div>
                    )}
                    {gameType === 'onlineLink' && (
                        <React.Fragment>
                            <div className='myColor'>White Connected: {whiteConnected.toString()}</div>
                            <div className='myColor'>Black Connected: {blackConnected.toString()}</div>
                        </React.Fragment>
                    )}
                    <Input
                        label='Show Possible Moves'
                        type='checkbox'
                        value={showPossibleMoves}
                        onChange={setShowPossibleMoves}
                    />
                    <Input
                        label='Show Square Names'
                        type='checkbox'
                        value={showSquareNames}
                        onChange={setShowSquareNames}
                    />

                    <div className='whiteCapturedPieces'>
                        <div>White Captured Pieces</div>
                        {whiteCapturedPieces.map((piece) => (
                            <div
                                className={classNames(`gamePiece ${piece.color}`)}
                                key={piece.name}
                            >
                                <img src={pieceImages[piece.color + piece.type]} alt={`${piece.color} ${piece.type}`} />
                            </div>
                        ))}
                    </div>
                    <div className='blackCapturedPieces'>
                        <div>Black Captured Pieces</div>
                        {blackCapturedPieces.map((piece) => (
                            <div
                                className={classNames(`gamePiece ${piece.color}`)}
                                key={piece.name}
                            >
                                <img src={pieceImages[piece.color + piece.type]} alt={`${piece.color} ${piece.type}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Chess;
