/* eslint-disable react-hooks/exhaustive-deps */
// ported from /Users/stevensantos/Documents/Drexel/2 Sophomore/Fall 2015/ECEC 301/Assignments/src/Project1/gameoflife.py

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

// import { padNum, round } from 'svs-utils/web';
import { Input, useInterval, useWindowListener } from 'svs-utils/react';

import { useStateSlice } from '../../utils/reactUtils.js';
import * as patterns from './patterns.js';

import './gameOfLife.scss';

function GameOfLife(props) {
    var [gameSlice, setGameSlice] = useStateSlice('gameOfLife');

    // var [gameState, setGameState] = useState('paused');
    var [dragPattern, setDragPattern] = useState(null);
    // var [world, setWorld] = useState(null);
    // var [worldType, setWorldType] = useState('random'); // empty, random
    // var [worldHeight, setWorldHeight] = useState(75);
    // var [worldWidth, setWorldWidth] = useState(100);
    // var [windowHeight, setWindowHeight] = useState(window.innerHeight);

    var dragPatternRef = useRef(null);
    var worldRef = useRef(null);

    var { delay, gameState, world, worldType, worldHeight, worldWidth, windowHeight } = gameSlice;

    useEffect(() => {
        if (!world) {
            setGameSlice({ world: generateWorld(), windowHeight: window.innerHeight });
        }

        return () => setGameSlice({ gameState: 'paused' });
    }, []);

    var handleResize = (event) => setGameSlice({ windowHeight: window.innerHeight });
    useWindowListener('resize', handleResize);

    var generateWorld = (empty = false) => {
        var rowCount = worldHeight;
        var columnCount = worldWidth;

        var world = [];
        for (var i = 0; i < rowCount; i++) {
            var row = [];
            if (!empty && worldType === 'random') {
                row = Array.from({ length: columnCount }, () => Math.random() < 0.1 ? 1 : 0);
            } else {
                row = Array.from({ length: columnCount }, () => 0);
            }
            world.push(row);
        }

        return world;
    };

    var startPauseSimulation = () => {
        setGameSlice({ gameState: (gameState === 'paused' ? 'running' : 'paused') });
    };

    var cellClicked = (i, j) => {
        if (gameState === 'paused') {
            world[i][j] = world[i][j] ? 0 : 1;
            setGameSlice({ world });
        }
    };

    var updateFrame = () => {
        setGameSlice((currentSlice) => {
            let { world: currentWorld, worldHeight, worldWidth } = currentSlice;
            var newWorld = currentWorld.map((row) => [...row]);

            for (var [i, row] of currentWorld.entries()) {
                for (var [j, cell] of row.entries()) {
                    var neighbors = 0;

                    for (var k = i - 1; k <= i + 1; k++) {
                        for (var l = j - 1; l <= j + 1; l++) {
                            if (k !== i || l !== j) {
                                var kIndex = k === -1 ? (worldHeight - 1) : k;
                                var lIndex = l === -1 ? (worldWidth - 1) : l;

                                if (currentWorld[kIndex] != null) {
                                    if (currentWorld[kIndex][lIndex] != null) {
                                        neighbors += currentWorld[kIndex][lIndex];
                                    } else {
                                        neighbors += currentWorld[kIndex][0];
                                    }
                                } else {
                                    if (currentWorld[0][lIndex] != null) {
                                        neighbors += currentWorld[0][lIndex];
                                    } else {
                                        neighbors += currentWorld[0][0];
                                    }
                                }
                            }
                        }
                    }

                    if (cell === 1) {
                        if (neighbors < 2 || neighbors > 3) {
                            newWorld[i][j] = 0;
                        }
                    } else {
                        if (neighbors === 3) {
                            newWorld[i][j] = 1;
                        }
                    }
                }
            }

            return { world: newWorld };
        });
    };

    useInterval(updateFrame, gameState === 'running' && delay);

    var { mouseDown } = useDragPattern(dragPatternRef, worldRef, setDragPattern);

    var worldHeightPx = windowHeight - 80;

    var squareSize = worldHeightPx / worldHeight;

    return (
        <div className='gameOfLifeContainer'>
            <div className={classNames('gameOfLife', gameState)}>
                <div className='worldTitle'>Conway's Game of Life</div>
                <div className='world' ref={worldRef} style={{ aspectRatio: worldWidth / worldHeight }}>
                    {world && world.map((row, i) => (
                        <div className='worldRow' key={i} style={{ gridTemplateColumns: `repeat(${worldWidth}, 1fr)` }}>
                            {row.map((cell, j) => (
                                <div
                                    className={classNames(`worldCell cell${cell}`)}
                                    key={j}
                                    onClick={() => cellClicked(i, j)}
                                >
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className='worldInfoContainer'>
                <Input
                    type='button'
                    label={gameState === 'paused' ? 'Start!' : 'Pause'}
                    onClick={() => startPauseSimulation()}
                />
                {gameState === 'paused' && (
                    <React.Fragment>
                        <Input
                            type='button'
                            label={'New World'}
                            onClick={() => setGameSlice({ world: generateWorld() })}
                        />
                        <Input
                            type='button'
                            label={'Clear World'}
                            onClick={() => setGameSlice({ world: generateWorld(true) })}
                        />
                    </React.Fragment>
                )}
                <div className='patternsList'>
                    {Object.keys(patterns).map((patternName) => {
                        var pattern = patterns[patternName];
                        return (
                            <div className='pattern' key={patternName}>
                                <div>{patternName}</div>
                                <div
                                    className='world'
                                    style={{
                                        gridTemplateRows: `repeat(${pattern.length}, 1fr)`,
                                        height: pattern.length * squareSize,
                                        width: pattern[0].length * squareSize,
                                    }}
                                    onMouseDown={(event) => mouseDown(event, patternName)}
                                >
                                    {pattern.map((row, i) => (
                                        <div className='worldRow' key={i} style={{ gridTemplateColumns: `repeat(${row.length}, 1fr)` }}>
                                            {row.map((cell, j) => (
                                                <div
                                                    className={classNames(`worldCell cell${cell}`)}
                                                    key={j}
                                                    // onClick={() => cellClicked(i, j)}
                                                >
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {dragPattern && (
                <div
                    className='world'
                    ref={dragPatternRef}
                    style={{
                        gridTemplateRows: `repeat(${dragPattern.length}, 1fr)`,
                        height: dragPattern.length * squareSize,
                        width: dragPattern[0].length * squareSize,
                        position: 'absolute',
                        top: dragPatternRef.moveData.top,
                        left: dragPatternRef.moveData.left,
                        backgroundColor: '#fff',
                    }}
                >
                    {dragPattern.map((row, i) => (
                        <div className='worldRow' key={i} style={{ gridTemplateColumns: `repeat(${row.length}, 1fr)` }}>
                            {row.map((cell, j) => (
                                <div
                                    className={classNames(`worldCell cell${cell}`)}
                                    key={j}
                                    // onClick={() => cellClicked(i, j)}
                                >
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

function useDragPattern(dragPatternRef, worldRef, setDragPattern) {
    var [gameSlice, setGameSlice] = useStateSlice('gameOfLife');

    var { gameState, world, worldHeight, worldWidth } = gameSlice;

    var mouseDown = (event, patternName) => {
        if (gameState !== 'paused' || event.button !== 0) {
            return;
        }

        var containerBox = event.target.closest('.gameOfLifeContainer').getBoundingClientRect();
        var box = event.target.closest('.world').getBoundingClientRect();

        var offsetX = event.clientX - box.x + containerBox.x;
        var offsetY = event.clientY - box.y + containerBox.y;

        dragPatternRef.moveData = {
            containerBox,
            offsetX,
            offsetY,
            left: box.x - containerBox.x,
            top: box.y - containerBox.y,
            pattern: patterns[patternName],
        };
        setDragPattern(patterns[patternName]);

        window.addEventListener('mousemove', mouseMove);
        window.addEventListener('mouseup', mouseUp);
        document.getElementsByTagName('body')[0].classList.add('noSelect');
    };

    var mouseMove = (event) => {
        dragPatternRef.moveData.left = event.clientX - dragPatternRef.moveData.offsetX;
        dragPatternRef.moveData.top = event.clientY - dragPatternRef.moveData.offsetY;

        dragPatternRef.current.style.left = `${dragPatternRef.moveData.left}px`;
        dragPatternRef.current.style.top = `${dragPatternRef.moveData.top}px`;
    };

    var mouseUp = (event) => {
        var { containerBox } = dragPatternRef.moveData;
        var box = worldRef.current.getBoundingClientRect();
        var left = event.clientX - dragPatternRef.moveData.offsetX - box.x + containerBox.x;
        var top = event.clientY - dragPatternRef.moveData.offsetY - box.y + containerBox.y;

        var squareSize = box.height / worldHeight;
        var i = Math.floor(top / squareSize);
        var j = Math.floor(left / squareSize);
        console.log(i, j);

        for (var [patternI, row] of dragPatternRef.moveData.pattern.entries()) {
            for (var [patternJ, cell] of row.entries()) {
                var newI = (i + patternI) % worldHeight;
                var newJ = (j + patternJ) % worldWidth;
                world[newI][newJ] = cell;
            }
        }

        dragPatternRef.moveData = {};
        setDragPattern(null);
        setGameSlice({ world });

        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', mouseUp);
        document.getElementsByTagName('body')[0].classList.remove('noSelect');
    };

    return { mouseDown };
}

export default GameOfLife;
