import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
// import dateFormat from 'dateformat';

import { MainMenu } from 'svs-utils/react';

import { Braille, Chess, Game2048, GameOfLife, Minesweeper, TicTacToe, WordEvolution } from './index.js';
import './home.scss';

import img2048 from '../images/2048icon.png';
import brailleIcon from '../images/brailleIcon.png';
import chessIcon from '../images/chessIcon.png';
import gameOfLifeIcon from '../images/gameOfLifeIcon.png';
import minesweeperIcon from '../images/minesweeperIcon.png';
import ticTacToeIcon from '../images/ticTacToeIcon.png';
import wordEvolutionIcon from '../images/wordEvolutionIcon.png';

var menuItems = {
    '2048': {
        title: '2048',
        description: 'Play 2048!',
        content: () => <Game2048 />,
        img: img2048,
    },
    braille: {
        title: 'Braille',
        description: 'Type your message and see the braille',
        content: () => <Braille />,
        img: brailleIcon,
    },
    chess: {
        title: 'Chess',
        description: 'Play locally on one screen or online with a friend!',
        content: () => <Chess />,
        img: chessIcon,
    },
    gameoflife: {
        title: 'Conway\'s Game of Life',
        description: 'The classic Computer Science project',
        content: () => <GameOfLife />,
        img: gameOfLifeIcon,
    },
    minesweeper: {
        title: 'Minesweeper!',
        description: 'Play the classic computer game',
        content: () => <Minesweeper />,
        img: minesweeperIcon,
    },
    tictactoe: {
        title: 'Tic-Tac-Toe',
        description: 'Play your friend or try playing against the AI',
        content: () => <TicTacToe />,
        img: ticTacToeIcon,
    },
    // wordevolution: {
    //     title: 'Word Evolution',
    //     description: 'See different versions of password guessing',
    //     content: () => <WordEvolution />,
    //     img: wordEvolutionIcon,
    // },
};

function Home(props) {
    var navigate = useNavigate();
    var urlParams = useParams();

    var { homePath } = urlParams;

    var path = window.location.pathname;
    var backgroundColor = path === '/' ? 'rgb(227, 182, 61)' : '';

    return (
        <div className='home' style={{ backgroundColor }}>
            <MainMenu menuItems={menuItems} />
            <div className={classNames('homeContentContainer')}>
                {menuItems[homePath] ? menuItems[homePath].content() : (
                    <div className='welcomeContainer'>
                        <div className='welcomeMessage'>
                            <p style={{ fontSize: 20 }}>Welcome!</p>
                            <p style={{ fontSize: 14 }}>This is my fun site where I make my own versions of classic games and a few other side projects. Please enjoy!</p>
                        </div>
                        <div className='homeItemsContainer'>
                            {Object.entries(menuItems).map(([itemKey, item]) => (
                                <div className='homeItem boxShadow' key={item.title} onClick={() => navigate(`/${itemKey}`)}>
                                    <div className='itemTitle'>{item.title}</div>
                                    <img className='homeItemImage' src={item.img} alt={item.title} />
                                    <div className='itemDescription'>{item.description || ''}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className='aboutMeFooter'>Instagram: @svsantos42</div>
        </div>
    );
}

export default Home;
