import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { numberWithCommas, randomString } from 'svs-utils/web';
import { Input, useMobileSwipe, useWindowListener } from 'svs-utils/react';

import { useStateSlice } from '../../utils/reactUtils.js';

import './2048.scss';

function getAvailableSpaces(board) {
    var availableSpaces = [];
    for (var [i, row] of board.entries()) {
        for (var [j, cell] of row.entries()) {
            if (!cell) {
                availableSpaces.push({ i, j });
            }
        }
    }

    return availableSpaces;
}

function addRandomTile(board) {
    var availableSpaces = getAvailableSpaces(board);

    var random = Math.floor(Math.random() * availableSpaces.length);
    var newTile = { id: randomString() };
    if (Math.random() > 0.9) { // 0.75
        newTile.value = 4;
    } else {
        newTile.value = 2;
    }
    newTile = { ...newTile, ...availableSpaces[random] };

    board[newTile.i][newTile.j] = newTile;

    return newTile;
}

function boardsEqual(board1, board2) {
    return board1.every((row, i) => row.every((tile, j) => tile === board2[i][j]));
}

function isBoardTerminal(board) {
    for (var [i, row] of board.entries()) {
        for (var [j, tile] of row.entries()) {
            if (!tile || !tile.value) {
                return false;
            }

            var nextTile = row[j + 1];
            if (nextTile && tile.value === nextTile.value) {
                return false;
            }
            nextTile = board[i + 1]?.[j];
            if (nextTile && tile.value === nextTile.value) {
                return false;
            }
        }
    }

    return true;
}

function Game2048(props) {
    var [game2048, setGame2048] = useStateSlice('2048');
    var { board, endScreenVisible, gameState, score, tiles } = game2048;

    var animationTimeout = useRef(null);
    var gameBoardRef = useRef(null);

    var onArrow = (event) => {
        if (gameState === 'playing' && ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
            handleArrow(event.key);
            event.preventDefault();
        }
    };

    let onMobileSwipe = (event) => {
        if (gameState === 'playing' && ['SwipeLeft', 'SwipeRight', 'SwipeUp', 'SwipeDown'].includes(event.key)) {
            handleArrow(event.key.replace(/^Swipe/, 'Arrow'));
        }
    };

    useWindowListener('keydown', onArrow);
    useMobileSwipe(gameBoardRef.current, onMobileSwipe);

    useEffect(() => {
        // for testing to auto-start the game
        if (!board.length) {
            startNewGame();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var startNewGame = () => {
        var newBoard = Array.from({ length: 4 }, () => Array(4).fill(0));
        var newTiles = [addRandomTile(newBoard), addRandomTile(newBoard)];
        setGame2048({ board: newBoard, endScreenVisible: false, gameState: 'playing', score: 0, tiles: newTiles });
    };

    var handleArrow = (direction) => {
        if (animationTimeout.current) {
            return;
        }

        var transpose = (array) => array[0].map((_, colIndex) => array.map(row => row[colIndex]));

        var rows = [...board];
        var changeProperty = 'j';
        var shouldReverse = ['ArrowRight', 'ArrowDown'].includes(direction);
        if (['ArrowUp', 'ArrowDown'].includes(direction)) {
            rows = transpose(rows);
            changeProperty = 'i';
        }

        var newRows = [];
        for (var [i, row] of rows.entries()) {
            row = row.filter((cell) => cell.value);
            if (shouldReverse) {
                row.reverse();
            }
            var newRow = [];
            for (var j = 0; j < row.length; j++) {
                var tile = row[j];
                tile.popAnimation = false;

                var nextTile = row[j + 1];
                var newJ = shouldReverse ? (4 - newRow.length - 1) : newRow.length;
                if (nextTile && tile.value === nextTile.value) {
                    // adjacent same values, so they will stack
                    tile[changeProperty] = newJ;
                    nextTile[changeProperty] = newJ;

                    var newTile = { id: randomString(), value: tile.value * 2, popAnimation: true };
                    if (changeProperty === 'j') {
                        newTile = { ...newTile, i, j: newJ };
                    } else {
                        newTile = { ...newTile, i: newJ, j: i };
                    }
                    newRow.push(newTile);
                    score += newTile.value;

                    j++; // already handled next tile, so skip on next round
                } else {
                    tile[changeProperty] = newJ;
                    newRow.push(tile);
                }
            }
            while (newRow.length < 4) {
                newRow.push(0);
            }
            if (shouldReverse) {
                newRow.reverse();
            }
            newRows.push(newRow);
        }

        var newBoard = ['ArrowUp', 'ArrowDown'].includes(direction) ? transpose(newRows) : newRows;
        if (boardsEqual(board, newBoard)) {
            return;
        }

        animationTimeout.current = setTimeout(() => {
            addRandomTile(newBoard);

            var availableSpaces = getAvailableSpaces(newBoard);
            if (!availableSpaces.length) {
                if (isBoardTerminal(newBoard)) {
                    gameState = 'ended';
                    setTimeout(() => setGame2048({ endScreenVisible: true }), 2000);
                }
            }

            var newTiles = newBoard.reduce((acc, row) => [...acc, ...row.filter((t) => !!t)], []);
            setGame2048({ gameState, score, tiles: newTiles });

            animationTimeout.current = null;
        }, 100);

        setGame2048({ board: newBoard });
    };

    return (
        <div className='container2048' ref={gameBoardRef}>
            <div className='gameHeader'>
                <span style={{ float: 'left' }}>2048 Game</span>
                <span style={{ float: 'right' }}>Score: {numberWithCommas(score)}</span>
            </div>
            <div className='gameBoard'>
                {board.map((row, i) => row.map((value, j) => (
                    <div className='emptySpace' key={`${i},${j}`} name={`${i},${j}`}></div>
                )))}
                {tiles.map((tile) => (
                    <div className={classNames(`numberSpace row${tile.i} col${tile.j} number${tile.value}`, { popAnimation: tile.popAnimation })} key={tile.id}>
                        <div className='numberText'>{tile.value}</div>
                    </div>
                ))}
                {gameState === 'ended' && (
                    <div className={classNames('endedScreen', { endScreenVisible })}>
                        <div>
                            <div>Game Over</div>
                            <div>Final Score: {numberWithCommas(score)}</div>
                            <div>
                                <Input className='startGameAgainButton' type='button' label='New Game' onClick={startNewGame} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Game2048;
