/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBomb, faFlag } from '@fortawesome/free-solid-svg-icons'

import { padNum, round } from 'svs-utils/web';
import { Input, useInterval } from 'svs-utils/react';

import { useStateSlice } from '../../utils/reactUtils.js';

import './minesweeper.scss';

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

function Minesweeper(props) {
    var [mineSlice, setMineSlice] = useStateSlice('minesweeper');
    var { board, endTime, difficulty, gameMessage, gameState, timeDiff, startTime, winCount, lossCount } = mineSlice;

    var [aiRunning, setAiRunning] = useState(false);

    var minesweeperAI = useRef(null);

    useEffect(() => {
        if (!board) {
            setMineSlice({ board: createBoard(), gameState: 'created', gameMessage: '', startTime: null, endTime: null, timeDiff: null });
        }
    }, []);

    useEffect(() => {
        setMineSlice({ board: createBoard(), gameState: 'created', gameMessage: '', startTime: null, endTime: null, timeDiff: null, winCount: 0, lossCount: 0 });
    }, [difficulty]);

    var updateTimer = () => {
        endTime = endTime || new Date();
        var timeDiff = round((endTime - startTime) / 1000, 0);
        setMineSlice({ timeDiff });
    };
    useInterval(updateTimer, gameState === 'playing' && 1000);

    var startNewGame = () => {
        setMineSlice({ board: createBoard(), gameState: 'created', gameMessage: '', startTime: null, endTime: null, timeDiff: null });
    };

    var createBoard = () => {
        // easy: 10x10 - 10 bombs (100 squares)
        // medium: 16x16 - 40 bombs (256 squares)
        // hard: 16x30 - 99 bombs (480 squares)
        // hardV2: 24x20 - 99 bombs (480 squares)
        var rowCount = difficulty === 'easy' ? 10 : (difficulty === 'medium' ? 16 : 20);
        var columnCount = difficulty === 'easy' ? 10 : (difficulty === 'medium' ? 16 : 24);
        var bombCount = difficulty === 'easy' ? 10 : (difficulty === 'medium' ? 40 : 99);

        var board = Array.from({ length: rowCount }, () => Array.from({ length: columnCount }, () => ({
            bombNeighbors: 0,
            isBomb: false,
            hidden: true,
            flagged: false,
        })));

        minesweeperAI.current = new MinesweeperAI(rowCount, columnCount, bombCount, toggleFlagged);

        return board;
    };

    var fillBoard = (board, firstI, firstJ) => {
        var rowCount = difficulty === 'easy' ? 10 : (difficulty === 'medium' ? 16 : 20);
        var columnCount = difficulty === 'easy' ? 10 : (difficulty === 'medium' ? 16 : 24);
        var bombCount = difficulty === 'easy' ? 10 : (difficulty === 'medium' ? 40 : 99);

        for (var k = 0; k < bombCount; k++) {
            var i = getRandomInt(rowCount);
            var j = getRandomInt(columnCount);

            // always make first click a square with 0 bomb neighbors
            var aroundFirstClick = (i >= (firstI - 1) && i <= (firstI + 1)) && (j >= (firstJ - 1) && j <= (firstJ + 1));

            if (!aroundFirstClick && !board[i][j].isBomb) {
                board[i][j].isBomb = true;
                board[i][j].bombNeighbors = -1;
            } else {
                // already a bomb, try again
                k--;
            }
        }

        for (i = 0; i < rowCount; i++) {
            for (j = 0; j < columnCount; j++) {
                var cell = board[i][j];
                if (!cell.isBomb) {
                    for (k = -1; k <= 1; k++) {
                        for (var l = -1; l <= 1; l++) {
                            if (!(k === 0 && l === 0)) {
                                var neighbor = board?.[i + k]?.[j + l] || null;
                                if (neighbor && neighbor.isBomb) {
                                    cell.bombNeighbors++;
                                }
                            }
                        }
                    }
                }
            }
        }

        return board;
    };

    var cellClicked = (i, j) => {
        if (!['created', 'playing'].includes(gameState)) {
            return;
        }

        if (gameState === 'created') {
            board = fillBoard(board, i, j);
        }

        if (board[i][j].isBomb) {
            showCell(board, i, j);
            setMineSlice({ board, gameMessage: 'You Lost', gameState: 'lost', endTime: new Date(), lossCount: lossCount + 1 });
        } else if (board[i][j].hidden) {
            showCell(board, i, j);

            var winner = checkForWinner(board);
            if (winner) {
                setMineSlice({ board, gameMessage: 'You Won!', gameState: 'won', endTime: new Date(), winCount: winCount + 1 });
            } else {
                setMineSlice({ board, gameState: 'playing', startTime: (startTime || new Date()) });
            }
        }
    };

    var getRightClickHandler = (func) => {
        return (event) => {
            if (!event.ctrlKey && !event.shiftKey) {
                func(event);
                event.preventDefault();
            }
        }
    };

    var toggleFlagged = (i, j, flagged = null) => {
        setMineSlice((currentSlice) => {
            let { board, gameState } = currentSlice;

            if (!['created', 'playing'].includes(gameState)) {
                return;
            }

            if (board[i][j].hidden) {
                board[i][j].flagged = (typeof flagged === 'boolean' ? flagged : !board[i][j].flagged);
                return { board };
            }
        });
    };

    var checkForWinner = (board) => {
        for (var row of board) {
            for (var cell of row) {
                if ((cell.isBomb && !cell.hidden) || (!cell.isBomb && cell.hidden)) {
                    return false;
                }
            }
        }

        return true;
    };

    var showCell = (board, i, j) => {
        board[i][j].hidden = false;
        board[i][j].flagged = false;
        if (board[i][j].isBomb) {
            return;
        }

        minesweeperAI.current?.addKnowledge(`${i},${j}`, board[i][j].bombNeighbors);
        if (board[i][j].bombNeighbors === 0) {
            for (var k = -1; k <= 1; k++) {
                for (var l = -1; l <= 1; l++) {
                    if (k || l) {
                        var neighbor = board?.[i + k]?.[j + l] || null;
                        if (neighbor && neighbor.hidden) {
                            minesweeperAI.current?.addKnowledge(`${i + k},${j + l}`, neighbor.bombNeighbors);
                            neighbor.hidden = false;
                            neighbor.flagged = false;
                            board[i][j].flagged = false;
                            if (neighbor.bombNeighbors === 0) {
                                showCell(board, i + k, j + l);
                            }
                        }
                    }
                }
            }
        }
    };

    var makeAiMove = () => {
        if (!minesweeperAI.current) {
            return;
        }

        if (!['created', 'playing'].includes(gameState)) {
            if (endTime && (new Date() - endTime) > (15 * 1000)) {
                startNewGame();
            }
            return;
        }

        var move = minesweeperAI.current.getSafeMove();
        if (!move) {
            // if (aiRunning && gameState === 'playing' && minesweeperAI.current.moves_made.size) {
            //     alert('No more known spaces. Need to make random guess');
            //     setAiRunning(false);
            //     return;
            // }

            move = minesweeperAI.current.getRandomMove();
            if (!move) {
                console.log('No moves left to make.');
                return;
            }
        }

        var [i, j] = move.split(',').map((n) => parseInt(n));
        cellClicked(i, j);
        minesweeperAI.current.flagMines();
    };

    useInterval(makeAiMove, aiRunning && 400);

    var bombCount = difficulty === 'easy' ? 10 : (difficulty === 'medium' ? 40 : 99);
    var columnCount = difficulty === 'easy' ? 10 : (difficulty === 'medium' ? 16 : 24);
    var width = columnCount * 30;
    var difficulties = [
        { value: 'easy', label: 'Easy' },
        { value: 'medium', label: 'Medium' },
        { value: 'hard', label: 'Hard' },
    ];

    var timeMinutes = 0;
    var timeSeconds = 0;
    if (timeDiff) {
        timeMinutes = Math.floor(timeDiff / 60);
        timeSeconds = timeDiff % 60;
    }

    return (
        <div className='minesweeperContainer' style={{ gridTemplateColumns: `auto ${width}px auto`}}>
            <div></div>
            <div className='minesweeper'>
                <div className='gameTitle'>Minesweeper!</div>
                <div className={classNames('gameBoard', gameState)}>
                    {board && board.map((row, i) => (
                        <div className='boardRow' key={i} style={{ gridTemplateColumns: `repeat(${columnCount}, 1fr)` }}>
                            {row.map((cell, j) => (
                                <div
                                    className={classNames(
                                        `boardCell cell${cell.bombNeighbors}`,
                                        {
                                            hidden: cell.hidden,
                                            lightSquare: (i % 2 === 0) ? (j % 2 === 0) : (j % 2 === 1),
                                            darkSquare: (i % 2 === 1) ? (j % 2 === 0) : (j % 2 === 1),
                                        },
                                    )}
                                    key={j}
                                    onClick={() => cellClicked(i, j)}
                                    onContextMenu={getRightClickHandler(() => toggleFlagged(i, j))}
                                >
                                    {cell.isBomb ? <FontAwesomeIcon icon={faBomb} /> : (cell.bombNeighbors || '')}
                                    {cell.flagged && gameState !== 'won' && <FontAwesomeIcon className='flag' icon={faFlag} />}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className='gameInfoContainer'>
                    <Input
                        className='difficultySelect'
                        type='select'
                        selectItems={difficulties}
                        onChange={(value) => setMineSlice({ difficulty: value })}
                        value={difficulty}
                    />
                    <div>{bombCount} Bombs</div>
                    <div>{padNum(timeMinutes, 2)}:{padNum(timeSeconds, 2)}</div>
                </div>
                <div className='winLossCounter'>Wins: {winCount}, Losses: {lossCount}</div>
                {gameMessage && (
                    <React.Fragment>
                        <div className='gameMessage'>{gameMessage}</div>
                        <Input type='button' label='Play Again!' onClick={() => startNewGame()} />
                    </React.Fragment>
                )}
                <div className=''>
                    <Input
                        type='button'
                        onClick={() => setAiRunning(!aiRunning)}
                        label={aiRunning ? 'Stop AI' : 'Start AI'}
                    />
                    {['created', 'playing'].includes(gameState) && (
                        <Input
                            type='button'
                            onClick={makeAiMove}
                            label='Make One AI Move'
                        />
                    )}
                </div>
            </div>
            <div></div>
        </div>
    );
}

export default Minesweeper;

class Sentence {
    // Logical statement about a Minesweeper game
    // A sentence consists of a set of board cells and a count of the number of those cells which are mines.

    constructor(cells, count) {
        this.cells = new Set(cells);
        this.count = count;
        this.safeCells = new Set();
        this.mineCells = new Set();
    }

    equals(other) {
        var eqSet = (xs, ys) => xs.size === ys.size && [...xs].every((x) => ys.has(x));
        return this.count === other.count && eqSet(this.cells, other.cells);
    }

    __str__() {
        return `${this.cells} = ${this.count}`;
    }

    knownMines() { // Returns the set of all cells in this.cells known to be mines.
        return this.mineCells;
    }

    knownSafes() { // Returns the set of all cells in this.cells known to be safe.
        return this.safeCells;
    }

    markMine(cell) { // Updates internal knowledge representation given the fact that a cell is known to be a mine.
        if (this.cells.has(cell)) {
            this.cells.delete(cell);
            this.mineCells.add(cell);
            this.count -= 1;
        }
    }

    markSafe(cell) { // Updates internal knowledge representation given the fact that a cell is known to be safe.
        if (this.cells.has(cell)) {
            this.cells.delete(cell);
            this.safeCells.add(cell);
        }
    }
}


class MinesweeperAI {
    // Minesweeper game player

    constructor(height = 10, width = 10, bombCount = 10, toggleFlagged = null) {
        // Set initial height and width
        this.height = height;
        this.width = width;
        this.bombCount = bombCount;
        this.nbrOfSquares = width * height;
        this.toggleFlagged = toggleFlagged;

        // Keep track of which cells have been clicked on
        this.moves_made = new Set();

        // Keep track of cells known to be safe or mines
        this.mines = new Set();
        this.safes = new Set();

        // List of sentences about the game known to be true
        this.knowledge = [];
    }

    flagMines() {
        for (var cell of this.mines) {
            var [i, j] = cell.split(',').map((n) => parseInt(n));
            this.toggleFlagged(i, j, true);
        }
    }

    markMine(cell) { // Marks a cell as a mine, and updates all knowledge to mark that cell as a mine as well.
        this.mines.add(cell);
        for (var sentence of this.knowledge) {
            sentence.markMine(cell);
        }
    }

    markSafe(cell) { // Marks a cell as safe, and updates all knowledge to mark that cell as safe as well.
        this.safes.add(cell);
        for (var sentence of this.knowledge) {
            sentence.markSafe(cell);
        }
    }

    addKnowledge(cell, count) {
        /*
        Called when the Minesweeper board tells us, for a given safe cell, how many neighboring cells have mines in them.

        This function should:
            1) mark the cell as a move that has been made
            2) mark the cell as safe
            3) add a new sentence to the AI's knowledge base
               based on the value of `cell` and `count`
            4) mark any additional cells as safe or as mines
               if it can be concluded based on the AI's knowledge base
            5) add any new sentences to the AI's knowledge base
               if they can be inferred from existing knowledge
        */

        var [i, j] = cell.split(',').map((n) => parseInt(n));

        this.moves_made.add(cell);
        this.markSafe(cell);

        var sentenceCells = []
        for (var k = i - 1; k <= i + 1; k++) {
            for (var l = j - 1; l <= j + 1; l++) {
                if (0 <= k && k < this.height && 0 <= l && l < this.width) {
                    var newCell = `${k},${l}`;
                    if (this.mines.has(newCell)) {
                        // we already know this neighbor to be a mine, so decrease the count
                        count -= 1;
                    }
                    if (!this.safes.has(newCell) && !this.mines.has(newCell)) {
                        sentenceCells.push(newCell);
                    }
                }
            }
        }

        var sentence = new Sentence(sentenceCells, count);
        this.knowledge.push(sentence);

        this.checkSentence(sentence);

        var isSubset = (xs, ys) => [...xs].every((x) => ys.has(x)); // xs subset of ys
        var difference = (xs, ys) => new Set([...xs].filter((x) => !ys.has(x))) // xs - ys
        for (var a of this.knowledge) {
            for (var b of this.knowledge) {
                if (a.equals(b)) {
                    continue;
                }
                if (a.cells.size > 0 && isSubset(a.cells, b.cells)) {
                    var newCells = difference(b.cells, a.cells);
                    var newCount = b.count - a.count;
                    sentence = new Sentence(newCells, newCount);
                    if (!this.knowledge.find((k) => k.equals(sentence))) {
                        this.knowledge.push(sentence);
                    }
                }
            }
            this.checkSentence(a);
        }
    }

    getSafeMove() {
        // Returns a safe cell to choose on the Minesweeper board.
        // The move must be known to be safe, and not already a move
        // that has been made.

        // This function may use the knowledge in this.mines, this.safes
        // and this.moves_made, but should not modify any of those values.

        for (var cell of this.safes) {
            if (!this.moves_made.has(cell)) {
                console.log('AI making safe move.');
                return cell;
            }
        }

        return null;
    }

    getRandomMove() {
        // Returns a move to make on the Minesweeper board.
        // Should choose randomly among cells that:
        //     1) have not already been chosen, and
        //     2) are not known to be mines

        var countKnownSquares = this.safes.size + this.mines.size;
        if (countKnownSquares >= this.nbrOfSquares) {
            return null // no unknown moves to make
        }

        var getRandomInt = (max) => Math.floor(Math.random() * max);

        // calculate probability of guessing from all remaining cells
        var remainingCount = this.nbrOfSquares - this.moves_made.size;
        var remainingBombs = this.bombCount - this.mines.size;
        var allProbability = round(remainingBombs / remainingCount, 4);

        // sentences that still have unknown cells
        // figure out which one has the lowest probability of hitting a mine and choose from that cells set
        var sentences = this.knowledge.filter((s) => s.count > 0)
            .map((s) => [s, round(s.count / s.cells.size, 4)])
            .sort((a, b) => a[1] - b[1]);
        if (sentences.length) {
            if (sentences[0][1] < allProbability) {
                let items = Array.from(sentences[0][0].cells);
                console.log(`No known safe moves, AI making random move with probability: ${sentences[0][1]}`);
                return items[getRandomInt(items.length)];
            }
        }

        while (true) {
            var i = getRandomInt(this.height);
            var j = getRandomInt(this.width);
            var cell = `${i},${j}`;
            if (!this.safes.has(cell) && !this.mines.has(cell)) {
                console.log(`No known safe moves, AI making random move from all available. Probability: ${allProbability}`);
                return cell;
            }
        }
    }

    checkSentence(sentence) {
        var cell = null;
        if (sentence.count === 0) {
            for (cell of [...sentence.cells]) {
                this.markSafe(cell);
            }
        } else if (sentence.count === sentence.cells.size) {
            for (cell of [...sentence.cells]) {
                this.markMine(cell);
            }
        }
    }
}
