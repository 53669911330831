import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { AppLayout } from 'svs-utils/react';

import Home from './components/home.js';

function AppRouter(props) {
    return (
        <Routes>
            <Route path='/' element={<AppLayout />}>
                <Route path='404' element={<div>404</div>} />
                {/* <Route path='braille' element={<Braille />} />
                <Route path='chess/:gameId' element={<Chess />} />
                <Route path='chess' element={<Chess />} />
                <Route path='gameoflife' element={<GameOfLife />} />
                <Route path='minesweeper' element={<Minesweeper />} />
                <Route path='wordevolution' element={<WordEvolution />} />
                <Route index element={<Home />} /> */}
                <Route path=':homePath' element={<Home />}>
                    <Route path=':secondPath' element={<Home />} />
                </Route>
                <Route index element={<Home />} />
            </Route>
        </Routes>
    );
}

export default AppRouter;
